import {FC, useEffect, useState} from 'react';
import {Box, Button, Card, Grid2, Stack, TextField} from "@mui/material";
import SectionBase from "../../common/SectionBase";
import MenuItem from "@mui/material/MenuItem";
import {useAnswer} from "../../../context/AnswerContext";
import answerToString from "../../../logical/answerToString";
import {resetAnswer} from "../offer/simulator";

const offer = [
    {
        value: 'Select offer',
        label: '',
    },
    {
        value: 'BASIC',
        label: 'BASIC',
    },
    {
        value: 'DUO',
        label: 'DUO',
    },
    {
        value: 'PACK COMPLET',
        label: 'PACK COMPLET',
    }
];


const Contact: FC<{}> = ({}) => {
    const [answer, setAnswer] = useAnswer()
    const [message, setMessage] = useState(() => answerToString(answer))

    useEffect(() => {
        setMessage(() => answerToString(answer))
    }, [answer]);
    return (
        <SectionBase bgColor={"#D72426"} ancre="contact" title="Contact" colorReverse>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '100%'},
                }}
                noValidate
                autoComplete="true"
                mb={10}
            >
                <Card elevation={10} sx={{padding: 5}}>
                    <Grid2 container direction="row" gap={10} justifyContent="center">
                        <Grid2 size={{xs: 12, md: 5}}>
                            <TextField id="outlined-firstname" label="Prénom" variant="outlined"/>
                            <TextField id="outlined-name" label="Nom" variant="outlined"/>
                            <TextField id="outlined-email" label="E-mail" variant="outlined"/>
                            <TextField
                                id="outlined-select-offer"
                                select
                                label="Offre"
                                defaultValue="Select offer"
                                value={answer.offer}
                                disabled={answer.quote}
                                onChange={(event) => setAnswer((prev) => ({
                                    ...prev,
                                    offer: event.target.value,
                                }))} // Fonction pour gérer les modifications
                            >
                                {offer.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid2>
                        <Grid2 size={{xs: 12, md: 5}}>
                            <TextField id="outlined-basic" label="Message" variant="outlined" multiline rows={10}
                                       value={message}
                                       onChange={(e) => setMessage(e.target.value)}
                                       />
                        </Grid2>
                    </Grid2>
                    <Stack alignItems="end">
                        <Button size="small" sx={{px: 5}} onClick={() => resetAnswer(setAnswer)} variant="contained">Réinitialiser</Button>
                    </Stack>
                    <Stack alignItems="center">
                        <Button size="large" sx={{px: 20}} variant="contained" href={"mailto:antoine@obligis.fr?subject=Demande%20de%20devis&body=" + message}>Envoyer</Button>
                    </Stack>
                </Card>
            </Box>
        </SectionBase>
    );
};

export default Contact;

