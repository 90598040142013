import React, {FC, useState} from 'react';
import {Box, Button, Grid2, Stack, Typography} from "@mui/material";

const MultipleChoices: FC<{ data: any, handleClick: any, title?: string}>
    = ({data, handleClick, title}) => {
    const [choices, setChoices] = useState<string[]>([])

    const addChoice = (value: string) => {
        setChoices([...choices, value])
    }
    console.log(choices)
    return (
        <Box><Typography variant="h1" component="h3">{data.title ?? title}</Typography>
            <Grid2 container justifyContent="center">
                {data.data.map((item: any, index: number) => (
                    <Grid2 key={index} size={{xs:12, md:4}} px={2} mt={2.5}>
                        <Box height={120} sx={{
                            backgroundColor: choices.filter((place) => place === item.value).length > 0 ? "#203053" : "none",
                            alignContent: "center",
                            borderRadius: 5,
                            mb: 5,
                            border: "10px solid" + item.color,
                            color: choices.filter((place) => place === item.value).length > 0 ? "white" : "#203053",
                            ":hover": {
                                backgroundColor: "#203053",
                                cursor: "pointer",
                                color: "white",
                            }
                        }}
                             onClick={() => addChoice(item.value)}>
                            <Typography variant="h2" component="p" textAlign={"center"} lineHeight={1} whiteSpace="pre-wrap">
                                {item.value}
                            </Typography>
                        </Box>
                    </Grid2>
                ))
                }
            </Grid2>
            <Grid2 size={{xs:12, md:3}} px={2} mt={10}>
                <Stack alignItems="end">
                    <Button variant="contained" onClick={() => handleClick(data.next, choices)}>
                        <Typography variant="h2" component="span" textAlign={"center"} lineHeight={1} whiteSpace="pre-wrap">
                            OK
                        </Typography>
                    </Button>
                </Stack>
            </Grid2>
        </Box>
    );
};

export default MultipleChoices;
