import {FC} from 'react';
import Navbar from "../components/header/navbar/Navbar";
import {Typography} from "@mui/material";

const LegalNotices: FC<{}> = ({}) => {
    return (
        <>
            <Navbar/>
            <Typography textAlign="center" pt={10}>Texte de la mention legal</Typography>
        </>
    );
};

export default LegalNotices;
