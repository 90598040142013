import React, {FC, useState} from 'react';
import {Box, Grid2, Typography} from "@mui/material";
import SectionBase from "../../common/SectionBase";
import Simulator from "./simulator";
import cover from "../../../assets/pictures/frenchpac_cover.jpg";

const Offer: FC<{ offers: any[] }> = ({offers}) => {

    const [choice, setChoice] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = async (item: any) => {
        setChoice(item)
        setOpen(true)
    };

    return (
        <>
            <SectionBase title="Offres" bgColor={"#fff"} ancre="offer" colorTitle={"#fff"} style={{
                backgroundImage: `url(${cover})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed"
            }}>
                <Grid2 container justifyContent="center">
                    {offers.map((offer, index) => (
                        <Grid2 key={index} size={{xs:12, md:3}} px={2} mt={10}>
                            <Box height={200} id={offer.title} sx={{
                                bgcolor: "white",
                                alignContent: "center",
                                borderRadius: 5,
                                mb: 5,
                                borderTop: "10px solid" + offer.borderTop,
                                borderLeft: "10px solid" + offer.borderLeft,
                                borderRight: "10px solid" + offer.borderRight,
                                borderBottom: "10px solid" + offer.borderBottom,
                                color: "#203053",
                                ":hover": {
                                    backgroundColor: "#203053",
                                    cursor: "pointer",
                                    color: "white",
                                }
                            }}
                            onClick={() => handleOpen(offer.title)}>
                                <Typography variant="h2" textAlign={"center"} lineHeight={1} whiteSpace="pre-wrap">
                                    {offer.title}
                                </Typography>
                            </Box>
                        </Grid2>
                    ))}
                </Grid2>
            </SectionBase>
        <Simulator offer={choice} open={open} setOpen={setOpen} />
    </>
    );
};

export default Offer;

