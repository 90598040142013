import React, {CSSProperties, FC, useState} from 'react';
import {Box, Stack, styled, Typography} from "@mui/material";
import SectionBase from "../../common/SectionBase";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Testimonial: FC<{ testimonials: any }> = ({testimonials}) => {
    const [current, setCurrent] = useState(0)
    const [animation, setAnimation] = useState("")
    const nextMove = () => {
        setAnimation("next-move")
        // setTimeout(() => {setAnimation("")}, 2000)
    }
    const transition = (value: number) => {
        setAnimation("fade-out")
        setTimeout(() => {
            setCurrent(value)
        }, 500)
        setTimeout(() => {
            setAnimation("fade-in")
        }, 475)
    }

    return (
        <SectionBase bgColor={""} ancre="testimonials" title="Témoignages">
            <Stack direction="row" justifyContent='space-between' mt={2} mb={10} mx={5}>
                <NavigateBeforeIcon onClick={() => transition(current - 1)} cursor="pointer" sx={{
                    fontSize: "10em", bgcolor: 'brown',
                    visibility: (current > 0) ? "visible" : "hidden"
                }}/>
                {testimonials.map((testimonial: any, index: number) => (
                    current === index &&
                    <Box key={index}>
                        <Stack className={animation + " stack"}
                               sx={{display: {xs: 'block', md: 'none'}}}>
                            <img src={require("../../../" + testimonial.picture)} alt={testimonial.alt} style={style}/>
                            <Box className={animation + " box"} alignContent="center">
                                <Typography className={animation} variant="h4"
                                            component="p">{testimonial.content}</Typography>
                                <Stack direction="row" justifyContent="right" mt={2}>
                                    <Typography className={animation} variant="h6" component="cite" fontWeight="bold">
                                        {testimonial.author} - {testimonial.country}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                        <Stack key={index} direction="row" className={animation + " stack"}
                               sx={{display: {xs: 'none', md: 'flex'}}}>
                            <img src={require("../../../" + testimonial.picture)} alt={testimonial.alt} style={style}/>
                            <Box className={animation + " box"} alignContent="center">
                                <Typography className={animation} variant="h4"
                                            component="p">{testimonial.content}</Typography>
                                <Stack direction="row" justifyContent="right" mt={2}>
                                    <Typography className={animation} variant="h6" component="cite" fontWeight="bold">
                                        {testimonial.author} - {testimonial.country}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                ))}
                <NavigateNextIcon onClick={() => transition(current + 1)} cursor="pointer" sx={{
                    fontSize: "10em",
                    visibility: (current + 1 < testimonials.length) ? "visible" : "hidden"
                }}/>
            </Stack>
        </SectionBase>
    );
};

export default Testimonial;

const style: CSSProperties = {
    color: "white",
    height: 150,
    width: 150,
    margin: 25,
    borderRadius: "50%",
    backgroundColor: "grey",
    backgroundSize: "cover",
    backgroundPosition: "center"
}