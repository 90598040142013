import React from 'react';
import {Box, Container, ImageList, ImageListItem, Typography} from "@mui/material";
import pictures from "../../../data/pictures.json"

const PictureGallery = () => {
    return (
        <Box bgcolor={"#fff"} p={5} id="gallery">
            <Container>
                <Typography variant="h1" color={"#203053"}>Galeries d'images</Typography>
            <ImageList variant="masonry" cols={3} gap={8}>
                {pictures.map((item) => (
                    <ImageListItem key={item.title}>
                        <img
                            src={require("../../../" + item.img)}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            </Container>
        </Box>
    );
};

export default PictureGallery;
