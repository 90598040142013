import React, {FC} from 'react';
import logo from "../../logo.svg";
import {Box, Link, Stack, Typography} from "@mui/material";

const Footer:FC<{}> = ({}) => {
    return (
        <footer>
            <Stack height={50} alignItems="center" justifyContent="center" bgcolor={"#000"}>
                <Typography variant="body2" color="white">Tous droits réservés - ©2025 - French PAC</Typography>
                <Typography href="mentions-legales" variant="body1" color="white" component="a">Mentions légales</Typography>
            </Stack>
        </footer>
    );
};

export default Footer;
