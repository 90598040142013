import {Answer} from "../@types/answer";

const answerToString = (answer: Answer) => {
    //TODO transformer answer en string
    let html = ""
    if (answer.offer) {
        html = "Bonjour, \nje souhaite un devis pour : \n\n"
        html += `Offre : ${answer.offer} \n`;
        if (answer.feature)
            html += `Service : ${answer.feature} \n`;
        if (answer.activity)
            html += `Activité : ${answer.activity} \n`;
        if (answer.hours)
            html += `Nombre d'heures : ${answer.hours} \n`;
        if (answer.days)
            html += `Nombre de jours : ${answer.days} \n`;
        if (answer.places.length)
            answer.places.forEach(place => {
                html += `Lieux : ${place} \n`;
            })
        if (answer.activityNumber)
                html += `Nombre d'ateliers : ${answer.activityNumber} \n`;
        if (answer.lodging)
            html += `Hébergement : ${answer.lodging ? 'oui' : 'non'} \n`;
        if (answer.taxi)
            html += `Transport en voiture : ${answer.taxi ? 'oui' : 'non'} \n`;
        if (answer.personNumber)
            html += `Nombre de personnes : ${answer.personNumber} \n`;

        html += `\nMerci`;
    }

    return html
}
export default answerToString;