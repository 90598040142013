import React, {FC} from 'react';
import SectionBase from "../../common/SectionBase";
import {Box, Button, Grid2, Stack, Typography} from "@mui/material";
import data from "../../../data/data.json"

const Features: FC<{ colorReverse?: boolean }> = ({colorReverse}) => {
        return (
            <SectionBase bgColor={"white"} ancre={"features"}>
                <Grid2 container height={"70vh"}>
                    {data.features.map((feature, index) =>
                        <Grid2 key={index} size={{xs: 12, md: 4}} px={2}>
                            <Stack  height="100%" justifyContent="space-between">
                            <Box height={"40%"} width={"100%"} sx={{
                                backgroundImage: (feature.picture !== "") ? (`url(${require("../../../" + feature.picture)})`) : 'none',
                                backgroundColor: "grey",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                borderTopLeftRadius: 45,
                                borderTopRightRadius: 45
                            }}>
                            </Box>
                            <Stack justifyContent={"space-between"} height="60%"
                                   color={colorReverse ? "white" : "black"}>
                                <Box>
                                    <Typography variant="h2" align="center" color={feature.bgColor}>{feature.title}</Typography>
                                    <Typography variant="body1" align="justify"
                                                whiteSpace="pre-wrap">{feature.content}</Typography>
                                </Box>
                                <Button variant="contained" size="large" sx={{backgroundColor: feature.bgColor}} href="#contact">{feature.buttonText}</Button>
                            </Stack>
                            </Stack>
                        </Grid2>
                    )}
                </Grid2>
            </SectionBase>
        )
            ;
    }
;

export default Features;
