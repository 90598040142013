// ----------------------------------------------------------------------

// MODIFIER LE RATIO REM ICI

function pxToRem(value: any) {
    return `${value / 16}rem`;
}

function responsiveFontSizes({sm, md, lg}: any) {
    return {
        "@media (min-width:600px)": {
            fontSize: pxToRem(sm),
        },
        "@media (min-width:960px)": {
            fontSize: pxToRem(md),
        },
        "@media (min-width:1280px)": {
            fontSize: pxToRem(lg),
        },
    };
}

//const FONT_PRIMARY = "Roboto mono, Product sans, sans-serif";
const FONT_PRIMARY = "SNPro, Nunito, Product sans, sans-serif";
const FONT_SECONDARY = "Nunito, Product sans, sans-serif";

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    title: {
        fontFamily: FONT_PRIMARY,
        fontWeight: 700,
        lineHeight: 80 / 32,
        fontSize: pxToRem(70)
        //...responsiveFontSizes({ sm: 32, md: 32, lg: 32 }),
    },
    h1: {
        fontWeight: 700,
        lineHeight: 80 / 32,
        fontSize: pxToRem(40)
        //...responsiveFontSizes({ sm: 32, md: 32, lg: 32 }),
    },
    h2: {
        fontWeight: 700,
        lineHeight: 64 / 30,
        fontSize: pxToRem(35),
        //...responsiveFontSizes({ sm: 30, md: 30, lg: 30 }),
    },
    h3: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(26),
        // ...responsiveFontSizes({ sm: 26, md: 30, lg: 26 }),
    },
    h4: {
        fontFamily: FONT_SECONDARY,
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(22),
        // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
        fontFamily: FONT_SECONDARY,
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
        // ...responsiveFontSizes({ sm: 19, md: 20, lg: 16 }),
    },
    h6: {
        fontWeight: 700,
        lineHeight: 28 / 18,
        fontSize: pxToRem(14),
        // ...responsiveFontSizes({ sm: 18, md: 18, lg: 11 }),
    },
    subtitle1: {
        fontFamily: FONT_SECONDARY,
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontFamily: FONT_SECONDARY,
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    body1: {
        fontFamily: FONT_SECONDARY,
        lineHeight: 1.5,
        fontWeight: 600,
        fontSize: pxToRem(16),
    },
    body2: {
        fontFamily: FONT_SECONDARY,
        lineHeight: 16 / 12,
        fontSize: pxToRem(12),
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        // textTransform: "uppercase",
    },
    button: {
        fontWeight: 700,
        lineHeight: 24 / 14,
        fontSize: pxToRem(10),
        // textTransform: "capitalize",
    },
    item: {}
};
declare module '@mui/material/Typography' {interface TypographyPropsVariantOverrides {title: true;}}
export default typography;
