import React, {createContext, Dispatch, SetStateAction, useState} from 'react';
import './App.css';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Contact from "./components/body/contact/Contact";
import Offer from "./components/body/offer/Offer";
import Testimonial from "./components/body/testimonial/Testimonial";
import data from "./data/data.json"
import Section from './components/common/Section';
import Description from "./components/body/description/Description";
import PictureGallery from "./components/body/pictureGallery/PictureGallery";
import Features from "./components/body/features/Features";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LegalNotices from "./pages/LegalNotices";
import {Answer} from "./@types/answer";
import {AnswerContext} from './context/AnswerContext';

function App() {
    const [answer, setAnswer] = useState<Answer>(initAnswer)

    return (
        <>
            <AnswerContext.Provider value={[answer, setAnswer]}>
                <Router>
                    <Routes>
                        <Route path="/mentions-legales" element={<LegalNotices/>}/>
                        <Route path="/" element={
                            <>
                                <Header/>
                                <Description description={data.description}/>
                                <Features/>
                                <Offer offers={data.offers}/>
                                <Section bgColor={"#fff"} colorTitle={"#203053"} item={data.trainer}/>
                                <PictureGallery/>
                                <Section bgColor={"#203053"} item={data.about} reverse colorReverse/>
                                <Testimonial testimonials={data.testimonials}/>
                                <Contact/>
                                <Footer/>
                            </>
                        }/>
                    </Routes>
                </Router>
            </AnswerContext.Provider>
        </>
    );
}

export default App;

export const initAnswer = {
    offer: '',
    feature: '',
    activity: '',
    activityNumber:'',
    hours: '',
    days: '',
    places: [],
    lodging: false,
    taxi: false,
    personNumber: 0,
    quote:false
}
