import {createContext, Dispatch, SetStateAction, useContext} from "react";
import {Answer} from "../@types/answer";

export const AnswerContext = createContext<([Answer, Dispatch<SetStateAction<Answer>>]) | undefined>(undefined)

export const useAnswer = () => {
    const context = useContext(AnswerContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}