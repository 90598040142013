import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function createLinearGradient(color1: any, color2: any) {
    return `linear-gradient(to right, ${color1}, ${color2})`;
}

function createDiagonalGradient(color1: any, color2: any) {
    return `linear-gradient(125deg, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: "#FFFFFF",
    100: "#F9FAFB",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "#212B36",
    900: "#161C24",
    500_8: alpha("#919EAB", 0.08),
    500_12: alpha("#919EAB", 0.12),
    500_16: alpha("#919EAB", 0.16),
    500_24: alpha("#919EAB", 0.24),
    500_32: alpha("#919EAB", 0.32),
    500_48: alpha("#919EAB", 0.48),
    500_56: alpha("#919EAB", 0.56),
    500_80: alpha("#919EAB", 0.8),
};

const palette = {
    primary: {
        main: '#000',
        mainTransparency: {
            10: alpha("#9d62de", 0.1),
            20: alpha("#9d62de", 0.2),
            30: alpha("#9d62de", 0.3),
            40: alpha("#9d62de", 0.4),
            50: alpha("#9d62de", 0.5),
            60: alpha("#9d62de", 0.6),
            70: alpha("#9d62de", 0.7),
            80: alpha("#9d62de", 0.8),
            90: alpha("#9d62de", 0.9),
        }
    },
    secondary: {
        main: '#57decd',
        mainTransparency: {
            10: alpha("#57decd", 0.1),
            20: alpha("#57decd", 0.2),
            30: alpha("#57decd", 0.3),
            40: alpha("#57decd", 0.4),
            50: alpha("#57decd", 0.5),
            60: alpha("#57decd", 0.6),
            70: alpha("#57decd", 0.7),
            80: alpha("#57decd", 0.8),
            90: alpha("#57decd", 0.9),
        }
    }, tertiary: {
        main: '#57decd',
        mainTransparency: {
            10: alpha("#57decd", 0.1),
            20: alpha("#57decd", 0.2),
            30: alpha("#57decd", 0.3),
            40: alpha("#57decd", 0.4),
            50: alpha("#57decd", 0.5),
            60: alpha("#57decd", 0.6),
            70: alpha("#57decd", 0.7),
            80: alpha("#57decd", 0.8),
            90: alpha("#57decd", 0.9),
        }
    },

    error: {
        main: '#f85b55',
    },
    warning: {
        main: '#f8c248',
    },
    info: {
        main: '#55cef8',
    },
    success: {
        main: '#90f848',
    },
    button: {
        main: '#ffffff'
    }
}
export default palette;

