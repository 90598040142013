import React, {FC} from 'react';
import SectionBase from "../../common/SectionBase";
import {Stack, Typography} from "@mui/material";

const Description: FC<{ description: any }> = ({description}) => {
    return (
        <SectionBase bgColor={"#fff"} ancre="description">
            <Typography variant="h1" align="center" mb={5}>{description.content1}</Typography>
            <Stack gap={1}>
                <Typography variant="subtitle1" align="center" whiteSpace="pre-wrap">{description.content2}</Typography>
               {/*//TODO carte de france*/}
            </Stack>
        </SectionBase>
    );
};

export default Description;
