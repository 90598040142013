import React, {Dispatch, FC, useEffect, useState} from 'react';
import Choices from "./simulator/Choices";
import data from "../../../data/simulator_data.json"
import {Box, Button, List, ListItem, Modal, Stack, Typography} from "@mui/material";
import MultipleChoices from "./simulator/MultipleChoices";
import {useAnswer} from "../../../context/AnswerContext";
import {initAnswer} from "../../../App";

export const resetAnswer = (setAnswer: Dispatch<any>) => {
    setAnswer(initAnswer)
}

const Simulator: FC<{
    offer: string,
    open: boolean,
    setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
}> = ({offer, open, setOpen}) => {
    const [step, setStep] = useState<number>(0)
    const [answer, setAnswer] = useAnswer()

    useEffect(() => {
        console.log('step :' + step)
        console.log('answer :', answer)
    }, [answer, step]);

    const handleClick = async (next: number, value: any) => {
        setStep(next)
        switch (step) {
            case 0:
                return setAnswer(offer !== "PACK COMPLET" ?
                    {
                        ...answer, feature: offer === "BASIC" ?
                            data.basic.data[value].value
                            : data.duo.data[value].value, offer: offer
                    }
                    : {...answer, feature: "Le pack complet", days: data.days.data[value].value, offer: offer});
            case 1:
                return setAnswer({...answer, places: value});
            case 2:
                return setAnswer({...answer, hours: data.hours.data[value].value});
            case 3:
                return setAnswer({...answer, activity: data.activity.data[value].value});
            case 4:
                return setAnswer({...answer, activityNumber: data.activity_number.data[value].value});
            case 5:
            case 6: {
                return setAnswer({...answer, personNumber: value + 1});
            }
            case 7: {
                return setAnswer({...answer, days: data.days.data[value].value});
            }
            case 8:
                setStep(9)
                return setAnswer({...answer, lodging: data.boolean.data[value].true_value})
            case 9:
                setStep(6)
                return setAnswer({...answer, taxi: data.boolean.data[value].true_value})
            case 99: {
                resetAnswer(setAnswer)
                setStep(0)
            }
        }
    }

    const choice = () => {
        switch (offer) {
            case "BASIC":
                return <Choices data={data.basic} handleClick={handleClick}/>;
            case "DUO":
                return <Choices data={data.duo} handleClick={handleClick}/>;
            case "PACK COMPLET":
                return <Choices data={data.days} handleClick={handleClick}/>;
        }
    }

    const steps = () => {
        switch (step) {
            case 0:
                return choice()
            case 1:
                return <MultipleChoices data={data.places} handleClick={handleClick}/>
            case 2:
                return <Choices data={data.hours} handleClick={handleClick}/>
            case 3:
                return <Choices data={data.activity} handleClick={handleClick} />
            case 4:
                return <Choices data={data.activity_number} handleClick={handleClick} />
            case 5:
            case 6:
                return <Choices data={data.person_number} handleClick={handleClick} />
            case 7:
                return <Choices data={data.days} handleClick={handleClick} />
            case 8:
                return <Choices data={data.boolean} handleClick={handleClick}
                                title="Voulez-vous que je vous héberge ?"/>
            case 9:
                return <Choices data={data.boolean} handleClick={handleClick}
                                title="Voulez-vous que je vous transporte ?"/>
            case 99: {
                return <Stack alignItems="center" height="335px" justifyContent="space-evenly">
                    <Typography variant="h2" color={"#203053"} textAlign="center">Récapitulatif</Typography>
                    <List component="ul" sx={{mb: 3}}>
                        {answer.offer &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Offre
                                    :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.offer}</Typography>
                            </ListItem>
                        }
                        {answer.feature &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1"
                                            textAlign="center"> Service :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.feature}</Typography>
                            </ListItem>
                        }
                        {answer.activity &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Activité
                                    :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.activity}</Typography>
                            </ListItem>
                        }
                        {answer.places.length > 0 &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Lieux
                                    :</Typography>
                                {answer.places.map((place, index) => (
                                    <Typography key={index} component="li" variant="body1"
                                                textAlign="center"> {place}{(index + 1 !== answer.places.length) && ", "}</Typography>
                                ))}
                            </ListItem>
                        }
                        {answer.activityNumber.length > 0 &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Nombre d'ateliers
                                    :</Typography>
                                    <Typography component="span" variant="body1"
                                                textAlign="center"> {answer.activityNumber}</Typography>
                            </ListItem>
                        }
                        {answer.hours &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Nombre
                                    d'heures :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.hours}</Typography>
                            </ListItem>
                        }
                        {answer.days &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Nombre
                                    de jours :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.days}</Typography>
                            </ListItem>
                        }
                        {answer.personNumber > 0 &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Nombre
                                    de personnes :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.personNumber}</Typography>
                            </ListItem>
                        }
                        {answer.lodging &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Hébergement
                                    :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.lodging ? 'Oui' : 'non'}</Typography>
                            </ListItem>
                        }
                        {answer.taxi &&
                            <ListItem disablePadding component="li">
                                <Typography color={"#203053"} component="span" variant="body1" textAlign="center">Taxi
                                    :</Typography>
                                <Typography component="span" variant="body1"
                                            textAlign="center"> {answer.taxi? 'Oui' : 'non'}</Typography>
                            </ListItem>
                        }
                    </List>
                    <Button variant="contained" size="large" href="#contact" onClick={() => {
                        setOpen(false)
                        setAnswer({...answer, quote: true})
                        setStep(0)
                    }}>Demander un devis</Button>
                </Stack>
            }
        }
    }

    const handleClose = async () => {
        resetAnswer(setAnswer)
        setStep(0)
        setOpen(false)
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby={"modal_offer"}
            aria-describedby={"modal_offer"}
        >
            <Box sx={style}>
                {steps()}
            </Box>
        </Modal>
    );
};

export default Simulator;

const style = {
    position: 'absolute' as 'absolute',
    top:
        '50%',
    left:
        '50%',
    transform:
        'translate(-50%, -50%)',
    minWidth:
        800,
    minHeight:
        400,
    bgcolor: 'background.paper',
    borderRadius:
        5,
    boxShadow:
        24,
    p:
        4,
    "& li > span:first-of-type": {
        width: "175px",
        textAlign: "right",
    }
};
