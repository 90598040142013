import {FC} from 'react';
import {Box, Container, Divider, Stack, Typography} from "@mui/material";
import data from "../../../data/data.json"
import cover from "../../../assets/pictures/home_french-pac.jpg"
import Logo from "../../common/Logo";

const Hero: FC<{}> = ({}) => {
    return (
        <Box sx={{backgroundImage: `url(${cover})`, backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment:"fixed"}}>
            <Box sx={{backgroundColor: "rgba(0,0,0,0.15)"}}>
                <Container>
                    <Stack alignItems="center" px={10} py={"10vh"} id={"top"} height={65 + "%"} sx={{color: "#fff"}}
                           gap={2}>
                        <Logo width={150}/>
                        <Box>
                            <Typography variant="title" component="h1" align="center"
                                        dangerouslySetInnerHTML={{__html:data.app.title}}></Typography>
                            <Divider variant="middle" sx={{borderColor: "white"}}/>
                            <Typography variant="h2" component="h2" align="center"
                                        dangerouslySetInnerHTML={{__html: data.app.subTitle}}></Typography>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default Hero;
