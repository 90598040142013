import React, {FC} from 'react';
import {Box, Grid2, Typography} from "@mui/material";

const Choices: FC<{ data: any, handleClick: any, title?: string}>
    = ({data, handleClick, title}) => {
    return (
        <Box><Typography variant="h2" component="h3">{data.title ?? title}</Typography>
            <Grid2 container justifyContent="center">
                {data.data.map((item: any, index: number) => (
                    <Grid2 key={index} size={{xs: 12, md: data.data.length >= 4 ? 3 : 4}} px={2} mt={5}>
                        <Box height={150} sx={{
                            alignContent: "center",
                            borderRadius: 5,
                            border: "10px solid" + item.color,
                            color: "#203053",
                            ":hover": {
                                backgroundColor: "#203053",
                                cursor: "pointer",
                                color: "white",
                            }
                        }}
                             onClick={() => handleClick(data.next ?? item.next, index)}>
                            <Typography variant="body1" component="p" textAlign={"center"} lineHeight={1} whiteSpace="pre-wrap">
                                {item.value}
                            </Typography>
                        </Box>
                    </Grid2>
                ))
                }
            </Grid2>
        </Box>
    );
};

export default Choices;

const square = {
    borderRadius: 4,
    borderWidth: 2,
    borderColor: 'gray',
    borderStyle: 'solid',
}