import React, {FC} from 'react';
import {Box, Button, Grid2, Stack, Typography} from "@mui/material";
import SectionBase from "./SectionBase";

const Section: FC<{ bgColor?: string, item: any, reverse?: boolean, colorReverse?: boolean, colorTitle?: string }>
    = ({bgColor, item, reverse, colorReverse, colorTitle}) => {
    return (
        <SectionBase bgColor={bgColor} colorTitle={colorTitle} ancre={item.id} colorReverse={colorReverse}>
            <Grid2 container direction={reverse ? "row" : "row-reverse"} justifyContent="space-evenly">
                <Grid2 size={{xs:12, md:5}}>
                    <Box height={"35vh"} width={"100%"} sx={{
                        backgroundImage: (item.picture !== "") ? (`url(${require("../../" + item.picture)})`) : 'none',
                        backgroundColor: "grey",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderTopRightRadius: 45,
                        borderBottomRightRadius: 45
                    }}>
                    </Box>
                </Grid2>
                <Grid2 size={{xs:12, md:6}}>
                    <Stack justifyContent={"space-between"} height="100%" color={colorReverse ? "white" : "black"}>
                        <Stack>
                            {item.title &&
                                <Typography variant="h2" color={colorTitle}>{item.title}</Typography>
                            }
                            <Typography variant="body1" align="justify"
                                        whiteSpace="pre-wrap">{item.content}</Typography>
                        </Stack>
                        <Button variant="contained" size="large" href="#contact"
                                sx={{backgroundColor: colorTitle}}>{item.buttonText}</Button>
                    </Stack>
                </Grid2>
            </Grid2>
        </SectionBase>
    );
};

export default Section;
